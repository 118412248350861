/* eslint-disable space-before-function-paren */
import React from 'react'
import Login from '../admin/login/login'
import BackgroundImg from 'gatsby-background-image'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import './bannerLogin.scss'
import { useIntl } from 'gatsby-plugin-react-intl'
export default function BannerLogin(props) {
  // const sources = [
  //   props.mobile,
  //   {
  //     ...props.desktop,
  //     media: '(min-width: 768px)'
  //   }
  // ]
  const image = getImage(props.desktop)
  const bgImage = convertToBgImage(image)
  const imageMobile = getImage(props.mobile)
  const bgImageMobile = convertToBgImage(imageMobile)
  const intl = useIntl()

  return (
    <div className={`${props.className}`}>
      <BackgroundImg
        {...bgImage}
        Tag="div"
        className={`${props.paralax} d-lg-block d-none`}
      >
        {intl.locale === 'pt'
          ? <div className="container h-100">
            <div className="row h-100 align-items-center">
              <div className="col-lg-5 pt-lg-0 pt-5 pb-lg-0 pb-5">
                <div>
                  <h1 className="text-white">{props.titulo}</h1>
                  <p className="text-white">{props.texto}</p>
                  <span className="text-white font-size-15 font-Spinnaker">{props.disk}</span>
                </div>
              </div>
              <div className="col-lg-4 offset-lg-3 d-flex justify-content-lg-end justify-content-center p-0 pr-lg-4">
                <Login color={props.cor} path={props.path} originPath={props.originPath} />
              </div>
            </div>
          </div>
          : <div className="container h-100">
            <div className="row h-100 align-items-center">
              <div className="col pt-lg-0 pt-5 pb-lg-0 pb-5">
                <div>
                  <h1 className="text-white">{props.titulo}</h1>
                  <p className="text-white">{props.texto}</p>
                  <span className="text-white font-size-15 font-Spinnaker">{props.disk}</span>
                </div>
              </div>
            </div>
          </div>
        }
      </BackgroundImg>
      <BackgroundImg
        {...bgImageMobile}
        Tag="div"
        className={'login-paralax d-lg-none d-block'}
      >
        {intl.locale === 'pt'
          ? <div className="container h-100">
            <div className="row h-100 align-items-center">
              <div className="col-lg-4 offset-lg-2 pt-lg-0 pt-5 pb-lg-0 pb-5">
                <div>
                  <h1 className="text-white font-size-2">{props.titulo}</h1>
                  <p className="text-white">{props.texto}</p>
                  <span className="text-white font-size-15 font-Spinnaker">{props.disk}</span>
                </div>
              </div>
              <div className="col-lg-6 d-flex justify-content-lg-end justify-content-center px-0">
                <Login color={props.cor} path={props.path} originPath={props.originPath} />
              </div>
            </div>
          </div>
          : <div className="container h-100">
            <div className="row h-100 align-items-center">
              <div className="col offset-lg-2 pt-lg-0 pt-5 pb-lg-0 pb-5">
                <div>
                  <h1 className="text-white font-size-2">{props.titulo}</h1>
                  <p className="text-white">{props.texto}</p>
                  <span className="text-white font-size-15 font-Spinnaker">{props.disk}</span>
                </div>
              </div>
            </div>
          </div>
        }

      </BackgroundImg>
    </div>
  )
}
