import React, { useState } from 'react'
import { Link, navigate } from 'gatsby'
import { useIntl } from 'gatsby-plugin-react-intl'
import parse from 'html-react-parser'
// Scss
import './login.scss'

// Services
import { getUser, handleLogin, isBrowser } from '../../../services/auth'

const login = (props) => {
  const initialState = { email: '', password: '' }
  const [formState, setFormState] = useState(initialState)
  const [errorMessage, setErrorMessage] = useState('')
  const intl = useIntl()

  useState(() => {
    isBrowser() && localStorage.setItem('originPath', props.originPath)
  }, [])
  const setInput = (key, value) => {
    setFormState({ ...formState, [key]: value })
  }

  async function signIn () {
    // const user = getUser()
    handleLogin(formState).then(res => {
      if (res === 200) {
        const user = getUser()
        setTimeout(() => {
          window.Tawk_API.setAttributes({
            name: `${user.attributes.name}`,
            email: `${user.attributes.email}`
          }, function (error) {
            console.log(error)
          })
        }, 1000)
        navigate(props.path)
      } else {
        setErrorMessage(res)
      }
    })
  }

  return (
    <>
      <form className={`form-coleta-${props.color} py-4`} >
        <h4 className="text-white font-Spinnaker text-lg-nowrap">{parse(intl.formatMessage({ id: 'login.title' }))}</h4>
        <span className="text-white d-inline-block mb-5 font-Spinnaker">{parse(intl.formatMessage({ id: 'login.span' }))}</span>
        <div className="d-flex flex-column">
          <label className="text-white font-Spinnaker" htmlFor="email">{parse(intl.formatMessage({ id: 'login.email' }))}<span className="login-span"> *</span></label>
          <input
            className="mb-4"
            type="text"
            onChange={(event) => setInput('email', event.target.value)}
            name="email"
          />
          <label className="text-white font-Spinnaker" htmlFor="password">{parse(intl.formatMessage({ id: 'login.senha' }))}<span className="login-span"> *</span></label>
          <input
            className="mb-3"
            type="password"
            onChange={(event) => setInput('password', event.target.value)}
            name="password"
          />
          {
            !!errorMessage && (
              <div className="d-flex bg-lwart-darkblue align-items-center justify-content-center py-2 px-3 my-3 rounded ">
                <p className="bg-danger rounded-circle text-white text-center align-self-center mb-0" style={{ width: '25px', height: '25px' }} >!</p>
                <p className="text-center text-white mb-0 font-weight-bold w-75 font-login-error">
                  {errorMessage}
                </p>
              </div>
            )
          }
          <button
            type="button"
            className="text-white btn-entrar btn w-50 py-2 mb-3 font-Spinnaker"
            onClick={() => signIn()}>{parse(intl.formatMessage({ id: 'login.entrar' }))}</button>
          <span className="mb-5 font-Spinnaker"><Link className="login-span" to="/area-cliente/esqueci-a-senha/">{parse(intl.formatMessage({ id: 'login.esqueceu_senha' }))}</Link></span>
          <span className="text-white font-Spinnaker">{parse(intl.formatMessage({ id: 'login.primeiro_acesso' }))}<br /><Link className="login-span line-height-2" to="/area-cliente/criar-conta/"> {parse(intl.formatMessage({ id: 'login.registrar' }))}</Link></span>
        </div>
      </form>
    </>
  )
}

export default login
